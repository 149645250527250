
import { Options, Vue } from 'vue-class-component';
import { Modal } from "bootstrap";
import AuthService from '@/services/AuthService';

@Options({
  props: {
    btnText: String,
    btnClass: String
  }
})
export default class SignupModal extends Vue {
  declare $refs: {
    signupModalElement: HTMLDivElement
  };

  private email = '';
  private username = '';
  private password = '';
  private repeatPassword = '';
  private firstname = '';
  private lastname = '';

  passwordAndRepeatAreSame = true;
  errors: any[] = [];

  private reset() {
    this.email = '';
    this.username = '';
    this.password = '';
    this.repeatPassword = '';
    this.firstname = '';
    this.lastname = '';

    this.passwordAndRepeatAreSame = true;
    this.errors = [];
  }

  openModal() {
    this.reset();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.signupModalElement);
    if (modalInstance) {
      modalInstance.show();
    }
  }

  closeModal() {
    this.reset();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.signupModalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }

  mounted() {
    this.$refs.signupModalElement.addEventListener('hidden.bs.modal', () => this.reset());
  }

  async onSubmit() {
    if (this.password !== this.repeatPassword) {
      this.passwordAndRepeatAreSame = false;
    } else {
      try {
        AuthService.signup(this.username, this.email, this.password, this.firstname, this.lastname);
        this.reset();
        this.closeModal();
      } catch (error) {
        this.errors.push(error);
      }
    }
  }
}
