

import EventEmitter from "./EventEmitter";

class ConfirmationModalHelper {
  private modal:any;
  private successCB:any;
  private rejectCB: any;

  constructor() {
    EventEmitter.on("ConfirmationModalConfirmed", this.onConfirmed.bind(this));
    EventEmitter.on("ConfirmationModalRejected", this.onRejected.bind(this));
  }

  public confirm(title: string, message: string, onSuccess:any, onReject?: any) {
    this.successCB = onSuccess;
    this.rejectCB = onReject;
    EventEmitter.emit('ConfirmationModalShow', {title, message});
  }

  public onRejected() {
    if (this.rejectCB) {
      const callback = this.rejectCB;
      this.rejectCB = null;
      callback();
    }
  }

  public onConfirmed() {
    if (this.successCB) {
      const callback = this.successCB;
      this.successCB = null;
      callback();
    }
  }

}

export default new ConfirmationModalHelper();
