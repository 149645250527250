

import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    onSelected: Function,
    title: String
  }
})
export default class FileUpload extends Vue {
  private selectedFile: any = null;
  onSelected!: any;

  declare $refs: {
    file: any;
  }

  private onSuccess(response: any) {
    this.selectedFile = null;
    if (this.onSelected) {
      this.onSelected(response);
    }
  }

  onUpload() {
    this.$refs.file.click();
  }

  async onFileSelect() {
    console.log('onFileSelect', this.$refs.file.files);
    this.selectedFile = this.$refs.file.files[0];
    if (this.onSelected) {
      this.$refs.file.value = null;
      const selectedFile: string = this.selectedFile;
      this.selectedFile = null;
      this.onSelected(selectedFile);
    }
  }
}

