import axios from "axios";
import HttpService from "./HttpService";

class TempFileService extends HttpService {
  public async upload(file: any, onSuccess: any, onError: any, onProgress?: any) {
    const headers = {
      "Content-Type": "multipart/form-data"
    };

    const formData: FormData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`/files/temp/_upload`, formData, { headers, onUploadProgress: onProgress });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }
}

export default new TempFileService();