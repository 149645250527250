import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "confirmation-modal",
  ref: "confirmationModal",
  class: "modal fade",
  tabindex: "-1",
  "aria-labelledby": "confirmation-modal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-fullscreen-md-down" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "row d-flex justify-content-center" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "d-flex float-end" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onReject && _ctx.onReject(...args)), ["prevent"])),
            "aria-label": "Close"
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("form", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("button", {
                    class: "btn btn-secondary",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onReject && _ctx.onReject(...args)), ["prevent"]))
                  }, " No ")
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", {
                    class: "btn btn-primary",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args)), ["prevent"]))
                  }, " Yes ")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 512))
}