
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    links: Array,
    placeholder: String,
    type: String
  }
})
export default class MultiInputValueText extends Vue {
  declare $refs: {
    mvit: HTMLInputElement
  }

  private links!: string[];

  mvitValue = "";

  removeTag(tag: string) {
    let index = this.links.indexOf(tag);
    if (index > -1) {
      this.links.splice(index, 1);
    }
  }

  typing(event: KeyboardEvent) {
    if (event.code === "Space" || event.code === "Enter") {
      if (this.$refs.mvit.checkValidity() && this.mvitValue.toString().trim().length > 0) {
        if (this.links.indexOf(this.mvitValue) === -1) {
          this.links.push(this.mvitValue);
        }

        this.mvitValue = "";
      } else {
        event.preventDefault();
      }
    }
  }
}
