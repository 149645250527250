
import { Options, setup, Vue } from 'vue-class-component';
import { useAuthStore } from "@/store/AuthStore"; // @ is an alias to /src

import ConfirmationModalHelper from "@/libs/ConfirmationModalHelper";
import DistrictService from "@/services/DistrictService";
import DistrictModal from "@/components/DistrictModal.vue";

import ConfigurationService from "@/services/ConfigurationService";
import BuildingTypeModal from "@/components/BuildingTypeModal.vue";
import BuildingInteriorTypeModal from "@/components/BuildingInteriorTypeModal.vue";
import FloorTypeModal from "@/components/FloorTypeModal.vue";

@Options({
  components: { DistrictModal, BuildingTypeModal, BuildingInteriorTypeModal, FloorTypeModal },
})
export default class HomeView extends Vue {
  authStore = setup(() => useAuthStore());

  private districts: any[] = [];
  private buildingTypes: any[] = [];
  private buildingInteriorTypes: any[] = [];
  private floorTypes: any[] = [];

  async getDistricts() {
    const response: any = await DistrictService.getAllDistricts();
    this.districts = response.data.districts || [];
  }

  async getBuildingTypes() {
    const response: any = await ConfigurationService.getAllBuildingTypes();
    this.buildingTypes = response.data.buildingExteriorTypes || [];
  }

  async getBuildingInteriorTypes() {
    const response: any = await ConfigurationService.getAllBuildingInteriorTypes();
    this.buildingInteriorTypes = response.data.buildingInteriorTypes || [];
  }

  async getFloorTypes() {
    const response: any = await ConfigurationService.getAllFloorTypes();
    this.floorTypes = response.data.floorTypes || [];
  }

  async mounted() {
    await this.getDistricts();
    await this.buildingXTypesReset();
  }

  async districtUpdated() {
    await this.getDistricts();
  }
  async buildingXTypesReset() {
    await this.getBuildingTypes();
    await this.getBuildingInteriorTypes();
    await this.getFloorTypes();
  }

  async onDeleteDistrict(district: any) {
    ConfirmationModalHelper.confirm(
      "Delete District",
      `Are you sure you want to delete district '${district.name}'?`,
      async () => {
        await DistrictService.deleteDistrict(district.id, async () => {
          await this.authStore.currentSession();
          await this.getDistricts();
        });
      }
    );
  }

  async onDeleteBuildingType(buildingType: any) {
    ConfirmationModalHelper.confirm(
      "Delete Building Type",
      `Are you sure you want to delete building type '${buildingType.name}' [${buildingType.id}]?`,
      async () => {
        await ConfigurationService.deleteBuildingType(buildingType.id, async () => {
          await this.authStore.currentSession();
          await this.getBuildingTypes();
        });
      }
    );
  }

  async onDeleteBuildingInteriorType(buildingInteriorType: any) {
    ConfirmationModalHelper.confirm(
      "Delete Building Interior Type",
      `Are you sure you want to delete building type '${buildingInteriorType.name}' [${buildingInteriorType.id}]?`,
      async () => {
        await ConfigurationService.deleteBuildingInteriorType(buildingInteriorType.id, async () => {
          await this.authStore.currentSession();
          await this.getBuildingInteriorTypes();
        });
      }
    );
  }

  async onDeleteFloorType(floorType: any) {
    ConfirmationModalHelper.confirm(
      "Delete Floor Type",
      `Are you sure you want to delete floor type '${floorType.name}' [${floorType.id}]?`,
      async () => {
        await ConfigurationService.deleteFloorType(floorType.id, async () => {
          await this.authStore.currentSession();
          await this.getFloorTypes();
        });
      }
    );
  }
}
