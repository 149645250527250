import { defineStore } from "pinia";
import AuthService from "@/services/AuthService";
import User from '@/models/user'
import router from "@/router";


export type RootState = {
  user: User|null
};

export const useAuthStore = defineStore({
  id: "authStore",
  state: () =>    ({
      user: null
    } as RootState),

  actions: {

    async signin(username: string, password: string) {
      try {
        this.user = await AuthService.signin(username, password) as User|null;
        console.log('###',this.user);
      } catch (error) {
        this.user = null;
        throw error;
      }
    },

    async currentSession() {
      try {
        this.user = await AuthService.currentSession() as User;
        console.log('>>>',this.user)
      } catch (error) {
        this.user = null;
        console.log(error);
        router.push('/signin');
      }
    },

    logout() {
      try {
        AuthService.logout();
        this.user = null;
      } catch (error) {
        this.user = null;
        console.log(error)
      }      

      router.push('/signin');
    },

    isAdmin() {
      return this.isAuthenticated() && this.user?.isAdmin();
    },
  

    isAuthenticated() {
      return this.user !== null;
    },

    userId() {
      return this.user ? this.user.id : null;
    },
  
    userOwnsStore(storeId: number): boolean {
      return this.user ? this.user.ownsIt(storeId) : false;
    }
  
  },
});