
import { Options, Vue } from 'vue-class-component';
import { Modal } from "bootstrap";
import MultiValueInputText from "@/components/MultiValueInputText.vue";
import FileUpload from "@/components/FileUpload.vue";

import TempFileService from "@/services/TempFileService";
import DistrictService from '@/services/DistrictService';

interface Dto {
  anchorPoints?: number[],
  map?: string
}

@Options({
  props: {
    btnText: String,
    btnClass: String,
    btnIcon: String,
    modalTitle: String,
    district: Object,
    readonly: Boolean,
  },
  components: {
    MultiValueInputText,
    FileUpload
  },
  emits: ['on-update']
})
export default class DistrictModal extends Vue {
  declare $refs: {
    districtModalElement: HTMLDivElement
  };

  private district!: any;

  errors: any[] = [];
  anchorPointsAreEmpty = true;
  anchorPointsAsRange = false;
  anchorPointRange = {from: 0, to: 1};

  dto: Dto = {anchorPoints: [], map: ""};

  openModal() {
    this.reset();
    this.updateInternal();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.districtModalElement);
    if (modalInstance) {
      modalInstance.show();
    }
  }

  closeModal() {
    this.reset();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.districtModalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }

  reset() {
    this.errors = [];
    this.anchorPointsAreEmpty = true;
    this.anchorPointsAsRange = false;
    this.anchorPointRange = {from: 0, to: 1};
  }

  mounted() {
    this.$watch("district", () => {
      this.updateInternal();
    });

    this.$refs.districtModalElement.addEventListener('hidden.bs.modal', () => this.reset());
  }

  updateInternal() {
    if (this.district) {
      this.dto = {...this.district};
    } else {
      this.dto = {anchorPoints: [], map: ""};
    }

    this.anchorPointsAreEmpty = !Array.isArray(this.dto.anchorPoints) || this.dto.anchorPoints.length === 0;
  }

  async onFileSelectedForAsset(file: File) {
    await TempFileService.upload(
      file,
      (res: string) => this.dto.map = res,
      (error: any) => this.errors.push(error),
      (percent: any) => console.log(">>>>", percent)
    );
  }

  async onSubmit() {
    if (this.dto) {
      if (!this.anchorPointsAsRange && (!Array.isArray(this.dto.anchorPoints) || this.dto.anchorPoints.length === 0)) {
        this.anchorPointsAreEmpty = true;
      } else {
        try {
          if (this.anchorPointsAsRange) {
            let res = Array.from({ length: ((this.anchorPointRange.to + 1) - this.anchorPointRange.from) }, (_, i) => this.anchorPointRange.from + i);
            this.dto.anchorPoints = res;
          }

          if ('id' in this.dto) {
            await DistrictService.updateDistrict(this.dto);
          } else {
            await DistrictService.createDistrict(this.dto);
          }

          this.closeModal();
          this.$emit('on-update');
        } catch (error) {
          this.errors.push(error);
        }
      }

    }
  }
}
