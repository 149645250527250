import User from "@/models/user";
import axios from "axios";
import HttpService from "./HttpService";
import SessionService from "./SessionService";

class AuthService extends HttpService {
  public async signin(username: string, password: string) {
    const payload = { username, password };

    try {
      const response = await axios.post('/auth/signin', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      SessionService.saveCookie(response.data);
      this.currentSession();
      return Object.assign(new User(), response.data);

    } catch (error) {
      this.handleError(error, (error: any) => {
        throw new Error(error);
      });
    }
  }

  public async signup(username: string, email: string, password: string, firstname: string | undefined | null, lastname: string | undefined | null) {
    const payload = { username, email, password, firstname, lastname };
    return await axios.post('/auth/signup', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public logout() {
    SessionService.removeCookie();
  }

  public async currentSession(emit = true) {
    const response = await axios.get('/auth/currentSession', {
      headers:{
        'Accept': 'application/json'
      }
    });

    SessionService.updateCookie(response.data);
    return Object.assign(new User(), response.data)
  }

  public async refreshToken(refreshToken: string) {
    const response = await axios.post('/auth/refreshToken', { refreshToken }, {
      headers:{
        'Accept': 'application/json'
      }
    });

    SessionService.updateTokenInfo(response.data);
    return response.data;
  }
}


export default new AuthService();