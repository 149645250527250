import axios from "axios";
import SessionService from "@/services/SessionService";
import AuthService from "@/services/AuthService";

export default class HttpConfig {
  private baseUrl;
  private authStore;
  private minExpirationTime;

  private constructor(authStore: any) {
    this.authStore = authStore;
    axios.interceptors.request.use(this.requestSuccessInterceptor.bind(this), this.requestFailInterceptor.bind(this));
    axios.interceptors.response.use(this.responseSuccessInterceptor.bind(this), this.responseFailInterceptor.bind(this));
    this.baseUrl = `${process.env.VUE_APP_SERVER_URL}/api/${process.env.VUE_APP_SERVER_VERSION}`;
    this.minExpirationTime = parseInt(process.env.VUE_APP_MIN_EXPIRATION_TIME);
  }

  private async requestSuccessInterceptor(config: any) {
    console.log('requestSuccessInterceptor', config.url)
    if (!/\/(signin|signup|refreshToken)/.test(config.url)) {
      const tokenInfo: any = SessionService.getTokenInfo();
      if (tokenInfo) {
        let accessToken: string = tokenInfo.accessToken;

        if (tokenInfo.expiresIn < this.minExpirationTime) {
          const refreshToken: any = await AuthService.refreshToken(tokenInfo.refreshToken);
          accessToken = refreshToken.accessToken;
        }

        config.headers['x-access-token'] = SessionService.getAccessToken()
      }
    }

    config.url = `${this.baseUrl}${config.url}`;

    return config;
  }

  private requestFailInterceptor(error: any) {
    console.log('requestFailInterceptor')
    return Promise.reject(error);
  }

  private responseSuccessInterceptor(config: any) {
    console.log('responseSuccessInterceptor')
    return config;
  }

  private responseFailInterceptor(error: any) {
    console.log('responseFailInterceptor', error);
    if (error.response.status === 403) {
      this.authStore.logout();
    }

    return Promise.reject(error);
  }

  static initialize(authStore: any) {
    return new HttpConfig(authStore);
  }

}



