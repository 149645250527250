import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router'
import HttpConfig from './configs/HttpConfig'
import { useAuthStore } from '@/store/AuthStore'


const pinia = createPinia();
const app = createApp(App).use(pinia)
const authStore = useAuthStore()
HttpConfig.initialize(authStore);
authStore.currentSession().then(() => app.use(router).mount('#app'));



