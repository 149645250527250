import axios from "axios";
import HttpService from "./HttpService";

class DistrictService extends HttpService {
  public async getAllDistricts() {
    return await axios.get("/districts");
  }

  public async findDistrict(districtId: number, onSuccess: any, onError: any) {
    const headers = {
      "Accept": "application/json",
    };

    try {
      const response = await axios.get(`/districts/${districtId}`, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async createDistrict(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post("/districts", data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async updateDistrict(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(`/districts/${data.id}`, data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async deleteDistrict(districtId: number, onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/districts/${districtId}`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async deleteAllDisttricts(onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/districts`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error);
    }
  }
}

export default new DistrictService();