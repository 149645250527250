import axios from "axios";
import HttpService from "./HttpService";

class ConfigurationService extends HttpService {
  public async getAllBuildingTypes() {
    return await axios.get("/building-exterior-types");
  }

  public async findBuildingTypes(id: number, onSuccess: any, onError: any) {
    const headers = {
      "Accept": "application/json",
    };

    try {
      const response = await axios.get(`/building-exterior-types/${id}`, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async createBuildingType(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post("/building-exterior-types", data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async updateBuildingType(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(`/building-exterior-types/${data.id}`, data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async deleteBuildingType(id: number, onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/building-exterior-types/${id}`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async deleteAllBuildingTypes(onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/building-exterior-types`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  //

  public async getAllBuildingInteriorTypes() {
    return await axios.get("/building-interior-types");
  }

  public async findBuildingInteriorType(id: number, onSuccess: any, onError: any) {
    const headers = {
      "Accept": "application/json",
    };

    try {
      const response = await axios.get(`/building-interior-types/${id}`, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async createBuildingInteriorType(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post("/building-interior-types", data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async updateBuildingInteriorType(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(`/building-interior-types/${data.id}`, data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async deleteBuildingInteriorType(id: number, onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/building-interior-types/${id}`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async deleteAllBuildingInteriorTypes(onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/building-interior-types`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  //

  public async getAllFloorTypes() {
    return await axios.get("/floor-types");
  }

  public async findFloorType(id: number, onSuccess: any, onError: any) {
    const headers = {
      "Accept": "application/json",
    };

    try {
      const response = await axios.get(`/floor-types/${id}`, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async createFloorType(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post("/floor-types", data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async updateFloorType(data: any, onSuccess?: any, onError?: any) {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(`/floor-types/${data.id}`, data, { headers });
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async deleteFloorType(id: number, onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/floor-types/${id}`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }

  public async deleteAllFloorTypes(onSuccess?: any, onError?: any) {
    try {
      const response = await axios.delete(`/floor-types`);
      this.handleSuccess(response, onSuccess);
    } catch (error: any) {
      this.handleError(error, onError);
    }
  }
}

export default new ConfigurationService();