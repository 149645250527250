
import { Options, Vue } from 'vue-class-component';
import { Modal } from "bootstrap";
import MultiValueInputText from "@/components/MultiValueInputText.vue";
import FileUpload from "@/components/FileUpload.vue";

import TempFileService from "@/services/TempFileService";
import ConfigurationService from '@/services/ConfigurationService';

interface Dto {
  anchorPoints?: number[],
  image?: string
}

@Options({
  props: {
    btnText: String,
    btnClass: String,
    btnIcon: String,
    modalTitle: String,
    buildingInteriorType: Object,
    readonly: Boolean,
  },
  components: {
    MultiValueInputText,
    FileUpload
  },
  emits: ['on-update']
})
export default class BuildingInteriorTypeModal extends Vue {
  declare $refs: {
    buildingInteriorTypeModalElement: HTMLDivElement
  };

  private buildingInteriorType!: any;

  errors: any[] = [];
  anchorPointsAreEmpty = true;
  anchorPointsAsRange = false;
  anchorPointRange = {from: 0, to: 1};

  dto: Dto = {anchorPoints: [], image: ""};

  openModal() {
    this.reset();
    this.updateInternal();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.buildingInteriorTypeModalElement);
    if (modalInstance) {
      modalInstance.show();
    }
  }

  closeModal() {
    this.reset();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.buildingInteriorTypeModalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }

  reset() {
    this.errors = [];
    this.anchorPointsAreEmpty = true;
    this.anchorPointsAsRange = false;
    this.anchorPointRange = {from: 0, to: 1};
  }

  mounted() {
    this.$watch("buildingInteriorType", () => {
      this.updateInternal();
    });

    this.$refs.buildingInteriorTypeModalElement.addEventListener('hidden.bs.modal', () => this.reset());
  }

  updateInternal() {
    if (this.buildingInteriorType) {
      this.dto = {...this.buildingInteriorType};
    } else {
      this.dto = {anchorPoints: [], image: ""};
    }

    this.anchorPointsAreEmpty = !Array.isArray(this.dto.anchorPoints) || this.dto.anchorPoints.length === 0;
  }

  async onFileSelectedForAsset(file: File) {
    await TempFileService.upload(
      file,
      (res: string) => this.dto.image = res,
      (error: any) => this.errors.push(error),
      (percent: any) => console.log(">>>>", percent)
    );
  }

  async onSubmit() {
    if (this.dto) {
      if (!this.anchorPointsAsRange && (!Array.isArray(this.dto.anchorPoints) || this.dto.anchorPoints.length === 0)) {
        this.anchorPointsAreEmpty = true;
      } else {
        try {
          if (this.anchorPointsAsRange) {
            let res = Array.from({ length: ((this.anchorPointRange.to + 1) - this.anchorPointRange.from) }, (_, i) => this.anchorPointRange.from + i);
            this.dto.anchorPoints = res;
          }

          if (this.buildingInteriorType) {
            await ConfigurationService.updateBuildingInteriorType(this.dto);
          } else {
            await ConfigurationService.createBuildingInteriorType(this.dto);
          }

          this.closeModal();
          this.$emit('on-update');
        } catch (error) {
          this.errors.push(error);
        }
      }

    }
  }
}
