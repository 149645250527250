
import { Dropdown, Modal } from "bootstrap";
import { Options, setup, Vue } from "vue-class-component";
import ConfirmationModal from "./components/ConfirmationModal.vue";
import { useAuthStore } from "@/store/AuthStore";
import SignupModal from "./components/SignupModal.vue";

@Options({
  components: {
    ConfirmationModal,
    SignupModal,
  },
})
export default class App extends Vue {
  authStore = setup(() => useAuthStore());

  onLogout() {
    this.authStore.logout();
  }

  async mounted() {
    console.log("App mounted");
    Modal.getOrCreateInstance("#confirmation-modal");
    try {
      this.$nextTick(() => Dropdown.getOrCreateInstance("#profile-item"));
    } catch (error) {
      console.error("ERROR:", error);
    }
  }
}
