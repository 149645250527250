import mitt from 'mitt';

class EventEmitter {
  private emitter;

  constructor() {
    this.emitter = mitt();
  }

  public emit(eventId:string, eventArgs?: any) {
    this.emitter.emit(eventId, eventArgs);
  } 

  public on(eventId:string, callback: any) {
    this.emitter.on(eventId, callback);
  } 

}

export default new EventEmitter();