
import { Options, Vue } from 'vue-class-component';
import { Modal } from "bootstrap";
import MultiValueInputText from "@/components/MultiValueInputText.vue";
import FileUpload from "@/components/FileUpload.vue";

import TempFileService from "@/services/TempFileService";
import ConfigurationService from '@/services/ConfigurationService';

@Options({
  props: {
    btnText: String,
    btnClass: String,
    btnIcon: String,
    modalTitle: String,
    floorType: Object,
    readonly: Boolean,
  },
  components: {
    MultiValueInputText,
    FileUpload
  },
  emits: ['on-update']
})
export default class FloorTypeModal extends Vue {
  declare $refs: {
    floorTypeModalElement: HTMLDivElement
  };

  private floorType!: any;

  errors: any[] = [];

  dto = {image: ""};

  openModal() {
    this.reset();
    this.updateInternal();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.floorTypeModalElement);
    if (modalInstance) {
      modalInstance.show();
    }
  }

  closeModal() {
    this.reset();
    let modalInstance = Modal.getOrCreateInstance(this.$refs.floorTypeModalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }

  reset() {
    this.errors = [];
  }

  mounted() {
    this.$watch("floorType", () => {
      this.updateInternal();
    });

    this.$refs.floorTypeModalElement.addEventListener('hidden.bs.modal', () => this.reset());
  }

  updateInternal() {
    if (this.floorType) {
      this.dto = {...this.floorType};
    } else {
      this.dto = {image: ""};
    }
  }

  async onFileSelectedForAsset(file: File) {
    await TempFileService.upload(
      file,
      (res: string) => this.dto.image = res,
      (error: any) => this.errors.push(error),
      (percent: any) => console.log(">>>>", percent)
    );
  }

  async onSubmit() {
    if (this.dto) {
      try {
        if (this.floorType) {
          await ConfigurationService.updateFloorType(this.dto);
        } else {
          await ConfigurationService.createFloorType(this.dto);
        }

        this.closeModal();
        this.$emit('on-update');
      } catch (error) {
        this.errors.push(error);
      }
    }
  }
}
