import DateHelper from '@/libs/DateHelper'

export default class User {
  id?: number;
  username!: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  createdAt?: string;
  updatedAt?: string;
  roles: string[] = [];
  stores: number[] = [];

  public isAdmin(): boolean {
    return this.roles.includes('admin');
  }

  public ownsIt(storeId: number): boolean {
    return this.stores.includes(storeId) || this.isAdmin();
  }

  get created(): Intl.DateTimeFormat|string {
    return this.createdAt ? DateHelper.formatDate(this.createdAt) : '';
  }

  get updated(): Intl.DateTimeFormat|string {
    return this.updatedAt ? DateHelper.formatDate(this.updatedAt) : '';
  }

}



