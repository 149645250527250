import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { useAuthStore } from '@/store/AuthStore';


const isAuthenticated = (to: any, from:any, next: any) => {
  const authStore = useAuthStore()

  if (authStore.isAuthenticated()) {
    next();
  } else {
    next('/signin');
  }

}
const routes: Array<RouteRecordRaw> = [
  {
    path: "/signin",
    name: "signin",
    component: () =>
      import(/* webpackChunkName: "signin" */ "../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/stores",
    name: "stores",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "stores" */ "../views/StoresView.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/store/new",
    alias: "/store/:store/edit",
    name: "newstore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "store-edit" */ "../views/StoreDetailForm.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/store/:store/product/new",
    alias: "/store/:store/product/:product/edit",
    name: "newproduct",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "product-edit" */ "../views/ProductDetailForm.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/store/:store/product/:product",
    name: "product",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "product" */ "../views/ProductDetailView.vue"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/store/:store",
    name: "store",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/StoreDetailView.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/users",
    name: "users",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersView.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/profile",
    name: "profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/:catchAll(.*)",
    name: "errors",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "errors" */ "../views/ErrorsView.vue"),
  },
];

const router = createRouter({
  history: process.env.DIST_MODE !== 'production' ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
  routes
});


export default router
