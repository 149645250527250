export default class HttpService {
  protected handleSuccess(response: any, onSuccess?: any) {
    if (onSuccess) {
      onSuccess(response.data);
    } else {
      console.info("Request succeeded!");
    }
  }

  protected handleError(error: any, onError?: any) {
    if (onError) {
      onError(error.response.data || error.response);
    } else {
      console.error(error);
    }
  }
}
