
import { Vue } from "vue-class-component";
import { Modal } from "bootstrap";
import EventEmitter from "@/libs/EventEmitter";

export default class ConfirmationModal extends Vue {
  private title = "";
  private message = "";
  private modal: any;

  declare $refs: {
    confirmationModal: HTMLDivElement;
  };

   mounted() {
    this.modal = Modal.getOrCreateInstance(this.$refs.confirmationModal);
    EventEmitter.on('ConfirmationModalShow', this.onShow.bind(this))
  }

  onShow(options: any) {
    this.title = options.title;
    this.message = options.message;
    this.modal.show();
  }

  onConfirm() {    
    EventEmitter.emit("ConfirmationModalConfirmed");
    this.modal.hide();
  }

  onReject() {
    EventEmitter.emit("ConfirmationModalRejected");
    this.modal.hide();
  }
}
