
class SessionService {

  private profile: any;

  public saveCookie(signinInfo: any) {
    localStorage.setItem('user', JSON.stringify(signinInfo));
  }

  public updateCookie(sessionInfo: any) {
    const oldCookie = this.getCookie();
    const cookie = { ...oldCookie, ...sessionInfo };
    this.saveCookie(cookie);
  }
  public removeCookie() {
    localStorage.removeItem('user');
  }

  public isAuthenticated(): boolean {
    return this.getCookie().accessToken;
  }

  public isAdmin(): boolean {
    const cookie = this.getCookie();
    return (cookie.roles || []).includes('admin');
  }

  public getProfile() {
    const cookie = this.getCookie();
    return cookie.accessToken ? this.getCookie() : null;
  }

  public getTokenInfo(): any {
    const cookie = this.getCookie();
    if (cookie.accessToken) {
      const expiresIn: number = Math.round((new Date(cookie.expiresAt).getTime() - new Date().getTime()) / 3600000);
      return {
        accessToken: cookie.accessToken,
        refreshToken: cookie.refreshToken,
        expiresIn
      }
    } else {
      return null;
    }

  }

  public updateTokenInfo(info: any): any {
    const cookie = this.getCookie();
    cookie.accessToken = info.accessToken;
    cookie.refreshToken = info.refreshToken;
    cookie.lastSignedIn = info.lastSignedIn;
    cookie.expiresAt = info.expiresAt;
    this.saveCookie(cookie);
  }

  public getAccessToken(): any {
    const cookie = this.getCookie();
    if (cookie.accessToken) {
      return cookie.accessToken;
    } else {
      return '';
    }
  }

  private getCookie(): any {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }


}

export default new SessionService();